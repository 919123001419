/**
 * Created with IntelliJ IDEA.
 * User: mjaeger
 * Date: 10.12.13
 * Time: 18:10
 * To change this template use File | Settings | File Templates.
 */
import {IRootScope} from "../hpc";


export let InfoCtrl = [
    '$scope',
    '$rootScope',
    '$location',
    '$sessionStorage',
    '$sce', function ($s, $rs: IRootScope, $location, appModel, $sce) {

        $s.iFrameLoaded = false;

        $s.setFrameLoaded = function () {
            $s.$apply($s.iFrameLoaded = true);
        }

        $s.openIFrame = function (url) {
            console.log('Opening URL: ' + url);
            $s.iFrameSrc = url;
            $location.path('/iFrame').search({url: url});
        }

        $s.iframeHeight = window.innerHeight;

        if($rs.viewMode == 'mobile'){
            $s.iFrameSrc = $sce.trustAsResourceUrl($location.search().url);
        } else {
            window.open($location.search().url, '_blank');
            $rs.$back();
        }



    }];
